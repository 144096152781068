import React from "react";
import { motion } from "framer-motion";
import Typewriter from "typewriter-effect";
import CvButton from "../components/CvButton";
import { Link } from "react-router-dom";

function SubtitleWithTypewriter({ strings }) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.5, duration: 0.5 }}
      className="text-[13px] text-center text-[#a2a2a6]"
    >
      <Typewriter
        options={{
          strings,
          autoStart: true,
          loop: true,
          delay: 50,
        }}
      />
    </motion.div>
  );
}

function LandingPage() {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100,
      },
    },
  };

  return (
    <motion.div
      className="flex flex-col justify-center items-center h-[92vh] gap-4"
      id="section-home"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <motion.div variants={itemVariants}>
        <div>
          <motion.h1
            className="text-[68px] font-roboto font-bold uppercase tracking-[0.04em] mx-0 mt-[10px] mb-[5px] relative glitch-text text-center"
            data-text={"I'm Somesh"}
            initial={{ scale: 0.5, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{
              type: "spring",
              stiffness: 200,
              damping: 10,
              duration: 0.5,
            }}
          >
            I'm Somesh
          </motion.h1>
        </div>
        <SubtitleWithTypewriter
          strings={["UX/UI Designer and Front-end Developer.", "Based in Pune"]}
        />
      </motion.div>
      <motion.div className="mt-4 flex gap-6" variants={itemVariants}>
        <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
          <Link
            to={"/about"}
            className="btn shadow-lg hover:shadow-2xl transition-transform duration-200 ease-in-out rounded-3xl px-6 py-2 border-orange-500 border"
          >
            About Me
          </Link>
        </motion.div>
        <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
          <CvButton />
        </motion.div>
      </motion.div>
    </motion.div>
  );
}

export default LandingPage;
