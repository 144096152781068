"use client";

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import "./NotFound.css";

export default function NotFound() {
  const [glitchText, setGlitchText] = useState("404");

  useEffect(() => {
    const glitchInterval = setInterval(() => {
      const chars = "!<>-_\\/[]{}—=+*^?#________";
      let newText = "";
      for (let i = 0; i < 3; i++) {
        if (Math.random() < 0.1) {
          newText += chars[Math.floor(Math.random() * chars.length)];
        } else {
          newText += "404"[i];
        }
      }
      setGlitchText(newText);
    }, 50);

    return () => clearInterval(glitchInterval);
  }, []);

  return (
    <div className="h-[95vh] bg-zinc-900 flex flex-col items-center justify-center p-4">
      <motion.div
        className="text-center"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h1 className="text-7xl md:text-9xl font-bold text-white mb-4 relative">
          <span
            className="absolute top-0 left-0 -ml-2 text-red-500"
            aria-hidden="true"
            style={{
              clipPath: "inset(50% 0 0 0)",
              animation: "glitch-top 1s infinite linear alternate-reverse",
            }}
          >
            {glitchText}
          </span>
          {glitchText}
          <span
            className="absolute bottom-0 left-0 -ml-2 text-blue-500"
            aria-hidden="true"
            style={{
              clipPath: "inset(0 0 50% 0)",
              animation: "glitch-bottom 1.5s infinite linear alternate-reverse",
            }}
          >
            {glitchText}
          </span>
        </h1>
        <p className="text-sm text-gray-400 mb-8">Oops! Page not found</p>
        <button asChild>
          <Link
            to="/"
            className="text-white  rounded-lg shadow-lg hover:scale-105 hover:shadow-2xl transition-transform duration-200 ease-in-out btn"
          >
            Go Back Home
          </Link>
        </button>
      </motion.div>
    </div>
  );
}
