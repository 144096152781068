import { Mailbox, MapPinIcon as MapPinHouseIcon, Phone } from "lucide-react";
import React, { useState } from "react";
import { motion } from "framer-motion";
import emailjs from "emailjs-com";

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const date = new Date();

  console.log(date);
  const [successMessage, setSuccessMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    emailjs
      .send(
        process.env.REACT_APP_EMAIL_SERVICE_ID,
        process.env.REACT_APP_EMAIL_TEMPLATE_ID,
        formData,
        process.env.REACT_APP_EMAIL_USER_ID
      )
      .then((result) => {
        console.log(result.text);
      })
      .catch((error) => {
        console.log(error.text);
      });
    console.log("Form Data Submitted:", formData);
    setSuccessMessage("Thanks, your message has been sent successfully!");
    setFormData({ name: "", email: "", message: "" });
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100,
      },
    },
  };

  return (
    <motion.div
      className="flex items-center flex-col justify-center pt-20 pb-20 h-[92vh] w-full overflow-scroll"
      id="section-contacts"
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <motion.div className="text-center mb-6" variants={itemVariants}>
        <motion.h2 className="text-4xl font-bold text-center bg-gradient-to-r from-orange-500 to-pink-500 text-transparent bg-clip-text">
          Contact Me
        </motion.h2>
        <motion.p className="my-4 text-gray-400">
          If you have any questions or want to work together, feel free to send
          me a message.
        </motion.p>
      </motion.div>

      <motion.div
        className="flex flex-row justify-between drop-shadow-2xl opacity-1 rounded-lg p-8 w-[75%]  flex-wrap max-md:w-full"
        variants={itemVariants}
      >
        <motion.div
          className="flex gap-4 flex-col mb-8 justify-center"
          variants={itemVariants}
        >
          <motion.div variants={itemVariants}>
            <p className="text-lg">Contact Information</p>
          </motion.div>
          <motion.div className="flex gap-2 flex-row" variants={itemVariants}>
            <Phone size={28} className="text-orange-500" />
            <p>+91 9021461356</p>
          </motion.div>
          <motion.div className="flex gap-2 flex-row" variants={itemVariants}>
            <Mailbox size={28} className="text-orange-500" />
            <p className="">sddahatonde22@gmail.com</p>
          </motion.div>
          <motion.div className="flex gap-2 flex-row" variants={itemVariants}>
            <MapPinHouseIcon size={28} className="text-orange-500" />
            <p className="">Kharadi, Pune, Maharashtra</p>
          </motion.div>
        </motion.div>

        <motion.div
          className="contact_form w-2/3 max-md:w-full"
          variants={itemVariants}
        >
          <form onSubmit={handleSubmit} className="flex gap-4 flex-col w-full">
            <motion.input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Name"
              className="w-full p-3 border-b border-orange-500 outline-0 rounded-lg transition duration-200 ease-in-out bg-transparent"
              required
              variants={itemVariants}
            />

            <motion.input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Email"
              className="w-full p-3 border-b border-orange-500 outline-0 rounded-lg transition duration-200 ease-in-out bg-transparent"
              required
              variants={itemVariants}
            />

            <motion.textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder="Message"
              className="w-full p-3 h-32 border-b border-orange-500 outline-0 rounded-lg transition duration-200 ease-in-out bg-transparent"
              required
              variants={itemVariants}
            ></motion.textarea>

            <motion.button
              type="submit"
              className="btn w-1/2 rounded-lg hover:bg-orange-500 transition duration-200 ease-in-out self-end "
              variants={itemVariants}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Send Message
            </motion.button>
          </form>
          {successMessage && (
            <motion.div
              className="alert-success mt-4 text-green-600 font-semibold"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ type: "spring", stiffness: 100 }}
            >
              <p>{successMessage}</p>
            </motion.div>
          )}
        </motion.div>
      </motion.div>
    </motion.div>
  );
}

export default Contact;
