// "use client";

import React from "react";
import { motion } from "framer-motion";
import {
  Coffee,
  Book,
  Lightbulb,
  Globe,
  Heart,
  PlayCircle,
} from "lucide-react";

const AboutPage = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100,
      },
    },
  };

  return (
    <motion.div
      className="  text-gray-100 p-8 my-24"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <motion.div className="max-w-5xl mx-auto" variants={itemVariants}>
        <motion.h1
          className="text-4xl md:text-5xl font-bold mb-8 text-center bg-gradient-to-r from-orange-500 to-pink-500 text-transparent bg-clip-text"
          variants={itemVariants}
        >
          The Story Behind the Code
        </motion.h1>

        <motion.div
          className="flex flex-col md:flex-row items-center mb-12"
          variants={itemVariants}
        >
          <img
            src="/assets/images/profile.jpg"
            alt="Somesh Dahatonde"
            width={300}
            height={300}
            className="rounded-full mb-6 md:mb-0 md:mr-8 border-4 border-red-500"
          />
          <div>
            <h2 className="text-2xl font-semibold mb-4">Hi, I'm Somesh</h2>
            <p className="text-gray-300 mb-4">
              I'm not just a developer; I'm a digital craftsman on a mission to
              build beautiful, functional, and user-centric web experiences. My
              journey in tech is fueled by curiosity, creativity, and a
              relentless desire to learn.
            </p>
            <p className="text-gray-300">
              Based in tech hub of Pune, I blend the rich cultural heritage of
              Maharashtra with cutting-edge web technologies to create unique
              digital solutions.
            </p>
          </div>
        </motion.div>

        <motion.section className="mb-12" variants={itemVariants}>
          <h3 className="text-2xl font-semibold mb-6 flex items-center">
            <Lightbulb className="mr-2 text-orange-500" />
            What Drives Me
          </h3>
          <div className="bg-zinc-800 p-6 rounded-lg">
            <p className="mb-4">
              My passion for web development goes beyond just writing code. I'm
              driven by the power of technology to solve real-world problems and
              make a positive impact. Whether it's crafting intuitive user
              interfaces or optimizing backend performance, I approach each
              project as an opportunity to create something meaningful.
            </p>
            <p>
              I believe in the philosophy of lifelong learning. The
              ever-evolving nature of web technologies excites me, and I'm
              always eager to embrace new challenges and expand my skill set.
            </p>
          </div>
        </motion.section>

        <motion.section className="mb-12" variants={itemVariants}>
          <h3 className="text-2xl font-semibold mb-6 flex items-center">
            <Coffee className="mr-2 text-orange-500" />
            Beyond the Keyboard
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="bg-zinc-800 p-6 rounded-lg hidden">
              <h4 className="text-xl font-semibold mb-2 flex items-center">
                <Book className="mr-2 text-orange-500" />
                Avid Reader
              </h4>
              <p>
                When I'm not coding, you'll often find me lost in a book. I'm
                particularly fond of science fiction and tech literature, always
                seeking new ideas and perspectives.
              </p>
            </div>
            <div className="bg-zinc-800 p-6 rounded-lg">
              <h4 className="text-xl font-semibold mb-2 flex items-center">
                <Globe className="mr-2 text-orange-500" />
                Travel Enthusiast
              </h4>
              <p>
                I love exploring new places, both physically and virtually.
                Travel broadens my horizons and inspires my work with diverse
                cultural influences.
              </p>
            </div>
            <div className="bg-zinc-800 p-6 rounded-lg">
              <h4 className="text-xl font-semibold mb-2 flex items-center">
                <PlayCircle className="mr-2 text-orange-500" />
                YouTube Enthusiast
              </h4>
              <p>
                I enjoy watching YouTube videos in my free time, exploring
                topics like technology, travel, and entertainment. It’s a great
                way to relax and stay informed.
              </p>
            </div>
          </div>
        </motion.section>

        <motion.section variants={itemVariants}>
          <h3 className="text-2xl font-semibold mb-6 flex items-center">
            <Heart className="mr-2 text-orange-500" />
            Let's Connect
          </h3>
          <div className="bg-zinc-800 p-6 rounded-lg">
            <p className="mb-4">
              I'm always excited to connect with fellow developers, tech
              enthusiasts, and potential collaborators. Whether you want to
              discuss a project idea, share insights about the latest web
              technologies, or just have a friendly chat, feel free to reach
              out!
            </p>
            <p>
              Let's create something amazing together and push the boundaries of
              what's possible on the web.
            </p>
          </div>
        </motion.section>
      </motion.div>
    </motion.div>
  );
};

export default AboutPage;
