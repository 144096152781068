"use client";

import { useState } from "react";
import { motion } from "framer-motion";
import { ExternalLink } from "lucide-react";

const projects = [
  {
    id: 1,
    title: "Delhi High Court e-District Portal",
    description: "Web Application",
    url: "https://edhcr.nic.in/",
    img: "/assets/images/edhcr.png",
    tech: "Next.js, SCSS, Docker, i18n, NextRoute, SSR",
    details:
      "Built and maintained a robust portal for the Delhi High Court from scratch, incorporating server-side rendering (SSR) to enhance performance and user experience. Leveraged Next.js and SCSS for responsive and dynamic UI, Docker for efficient deployment, and i18n for multilingual support. The portal provides a seamless and high-performance experience, catering to a diverse user base with optimized load times and reliable accessibility.",
  },
  {
    id: 2,
    title: "ConnectFM Maintenance",
    description: "Web Application Maintenance",
    url: "https://connectfm.ca/",
    img: "/assets/images/connectfm.png",
    tech: "React.js, Nginx, Docker",
    details:
      "Maintained and optimized the ConnectFM website to ensure high performance and seamless functionality.",
  },
  {
    id: 3,
    title: "Data Insiders",
    description: "E-learning Platform",
    url: "https://www.datainsiders.in/",
    img: "/assets/images/datainsider.png",
    tech: "WordPress, Elementor, Razorpay (Payment Integration)",
    details:
      "Developed a fully functional e-learning platform for Data Insiders. Implemented an intuitive, user-friendly design using WordPress and Elementor, with integrated Razorpay for secure payment processing.",
  },
  {
    id: 4,
    title: "AI Bot",
    description: "AI-powered Chatbot",
    url: "https://airbot.netlify.app/",
    img: "/assets/images/aibot.png",
    tech: "React.js, RapidAPI, ChatGPT API",
    details:
      "Developed an AI-powered chatbot interface utilizing ChatGPT's API for interactive user engagement.",
  },
  {
    id: 5,
    title: "AIscribe",
    description: "AI Transcription Tool",
    url: "https://github.com/Somesh-Dahatonde/AIscribe",
    img: "/assets/images/3.png",
    tech: "Python (Flask), HTML, CSS, PrivateGPT (open-source model)",
    details:
      "Created an AI transcription tool to generate accurate transcriptions using a private GPT model integrated with Flask for a lightweight backend.",
  },
  {
    id: 6,
    title: "Discord Clone",
    description: "Web Application",
    url: "https://github.com/Somesh-Dahatonde/discord-clone",
    img: "/assets/images/3.png",
    tech: "React.js, Firebase, React-router-dom",
    details:
      "Developed a clone of the popular Discord application, showcasing proficiency in React.js and real-time database integration with Firebase.",
  },
];

const PortFolio = () => {
  const [selectedProject, setSelectedProject] = useState(null);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  return (
    <div className="min-h-screen text-gray-100 my-24">
      <main className="px-4 py-12">
        <div className=" px-4">
          <motion.h1
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2 }}
            className="text-4xl font-bold text-center bg-gradient-to-r from-orange-500 to-pink-500 text-transparent bg-clip-text"
          >
            My Portfolio
          </motion.h1>
        </div>
        <motion.div
          className="grid max-md:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 xl:gap-16 m-12"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          {projects.map((project) => (
            <motion.div
              key={project.id}
              className="bg-zinc-800 rounded-lg overflow-hidden shadow-lg transition-all duration-300 hover:shadow-2xl"
              variants={itemVariants}
              whileHover={{ y: -5 }}
            >
              <img
                src={project.img}
                alt={project.title}
                width={400}
                height={200}
                className="w-full h-48 object-cover"
              />
              <div className="p-6">
                <h2 className="text-2xl font-bold mb-2">{project.title}</h2>
                <p className="text-gray-400 mb-4">{project.description}</p>
                <div className="flex justify-between items-center">
                  <button
                    onClick={() => setSelectedProject(project)}
                    className="text-orange-500 hover:text-orange-400 transition-colors"
                  >
                    Learn More
                  </button>
                  <a
                    href={project.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-orange-500 hover:text-orange-400 transition-colors"
                  >
                    <ExternalLink className="w-5 h-5" />
                    <span className="sr-only">Visit {project.title}</span>
                  </a>
                </div>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </main>

      {selectedProject && (
        <motion.div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          onClick={() => setSelectedProject(null)}
        >
          <motion.div
            className="bg-zinc-800 p-6 rounded-lg max-w-2xl w-full"
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            onClick={(e) => e.stopPropagation()}
          >
            <h2 className="text-2xl font-bold mb-4">{selectedProject.title}</h2>
            <p className="text-gray-300 mb-4">{selectedProject.details}</p>
            <p className="text-orange-500 mb-4">
              <strong>Technologies:</strong> {selectedProject.tech}
            </p>
            <div className="flex justify-between items-center">
              <a
                href={selectedProject.url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-orange-500 hover:text-orange-400 transition-colors flex items-center"
              >
                <ExternalLink className="w-5 h-5 mr-2" />
                Visit Project
              </a>
              <button
                className="text-gray-400 hover:text-white transition-colors"
                onClick={() => setSelectedProject(null)}
              >
                Close
              </button>
            </div>
          </motion.div>
        </motion.div>
      )}
    </div>
  );
};

export default PortFolio;
