import React, { useState, useEffect } from "react";
import "./header.css";
import { Link, useLocation } from "react-router-dom";

function Header() {
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header
      className={`text-white p-6 z-50 top-0 left-0 w-full transition-all duration-300 ${
        isScrolled
          ? "fixed bg-[--bg-color] shadow-lg"
          : "absolute bg-transparent"
      }`}
    >
      <div className="flex flex-row w-full justify-between items-center">
        <div className="logo">
          <Link
            to="/"
            className="font-bold text-2xl bg-gradient-to-r from-orange-500 to-pink-500 bg-clip-text text-transparent"
          >
            <span>{`<Somesh.me/>`}</span>
          </Link>
        </div>

        <div className="top-menu max-md:hidden">
          <ul className="flex flex-row items-center gap-12 px-2 max-lg:gap-8">
            <li>
              <Link
                to="/"
                className={`${location.pathname === "/" ? "active" : ""}`}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/about"
                className={`${location.pathname === "/about" ? "active" : ""}`}
              >
                About
              </Link>
            </li>
            <li>
              <Link
                to="/resume"
                className={`${location.pathname === "/resume" ? "active" : ""}`}
              >
                Resume
              </Link>
            </li>
            <li>
              <Link
                to="/portfolio"
                className={`${
                  location.pathname === "/portfolio" ? "active" : ""
                }`}
              >
                Portfolio
              </Link>
            </li>
            <li>
              <Link
                to="/blog"
                className={`${location.pathname === "/blog" ? "active" : ""}`}
              >
                Blog
              </Link>
            </li>
            <li>
              <Link
                to="/contact"
                className={`${
                  location.pathname === "/contact" ? "active btn" : "btn"
                }`}
              >
                Contacts
              </Link>
            </li>
          </ul>
        </div>

        {/* mobile menu */}
        <div className="mobile-menu max-lg:block md:hidden">
          <input type="checkbox" id="menu-toggle" className="hidden" />
          <label htmlFor="menu-toggle" className="menu-icon cursor-pointer">
            <span className="block w-6 h-1 bg-white mb-1"></span>
            <span className="block w-6 h-1 bg-white mb-1"></span>
            <span className="block w-6 h-1 bg-white mb-1"></span>
          </label>

          <ul className="menu-content bg-gray-800 text-white p-4 absolute top-16 right-0 w-40 hidden">
            <li>
              <Link to="/" className="py-2">
                Home
              </Link>
            </li>
            <li>
              <Link to="/about" className="py-2">
                About
              </Link>
            </li>
            <li>
              <Link to="/resume" className="py-2">
                Resume
              </Link>
            </li>
            <li>
              <Link to="/portfolio" className="py-2">
                Portfolio
              </Link>
            </li>
            <li>
              <Link className="py-2" to="/blog">
                Blog
              </Link>
            </li>
            <li>
              <Link to="/contact" className="btn py-2">
                Contacts
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
}

export default Header;
