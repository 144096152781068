import React from "react";
import { motion } from "framer-motion";
import { ChevronUp } from "lucide-react";

function ScrollToTop() {
  const HandelScroll = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <motion.button
      className="fixed bottom-4 right-4 bg-orange-500 text-white p-2 rounded-full shadow-lg z-[100]"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      whileHover={{ scale: 1.1 }}
      onClick={HandelScroll}
    >
      <ChevronUp className="w-6 h-6" />
      <span className="sr-only">Scroll to top</span>
    </motion.button>
  );
}

export default ScrollToTop;
