import React from "react";
import { ExternalLink } from "lucide-react";
import { Link } from "react-router-dom";

function Blog() {
  return (
    <div className="p-6 h-[72vh] my-24 w-full flex flex-col justify-center items-center">
      <h1 className="text-2xl font-bold mb-4">My Blog</h1>
      <p className="text-gray-500 flex  flex-row gap-2 ">
        Check out my latest articles on{"  "}
        <Link
          to="https://medium.com/@someshdahatonde"
          target="_blank"
          className="flex flex-row  gap-2 text-orange-500 hover:text-orange-400 transition-colors"
        >
          Medium <ExternalLink />
        </Link>
      </p>
    </div>
  );
}

export default Blog;
