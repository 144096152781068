import "./App.css";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import LandingPage from "./pages/LandingPage";
import PortFolio from "./pages/PortFolio";
import { Route, Routes } from "react-router-dom";
import Resume from "./pages/Resume";
import Contact from "./pages/Contact";
import NotFound from "./pages/NotFound";
import ScrollToTop from "./components/ScrollToTop";
import CursorTracker from "./components/CursorTracker";
import AboutPage from "./pages/AboutPage";
import Blog from "./pages/Blog";

function App() {
  return (
    <div className="container">
      <div className="wrapper">
        <Header />

        <Routes>
          <Route exact path="/" element={<LandingPage />} />
          <Route path="/portfolio" element={<PortFolio />} />
          <Route path="/resume" element={<Resume />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <CursorTracker />

        <ScrollToTop />
        <Footer />
      </div>
    </div>
  );
}

export default App;
