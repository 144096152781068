import React from "react";
import { Download } from "lucide-react";

function CvButton() {
  const DownloadCv = () => {
    // create a function to download the cv file from the public folder

    const link = document.createElement("a");
    link.download = "Someshresume.pdf";
    link.href = "/cv/Someshresume.pdf";
    link.click();
  };

  return (
    <button
      className="flex items-center bg-orange-500 text-white px-4 py-2 rounded-full hover:bg-orange-600 transition-colors"
      onClick={DownloadCv}
    >
      <Download className="w-5 h-5 mr-2" />
      Download CV
    </button>
  );
}

export default CvButton;
