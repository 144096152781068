import React from "react";
import { Link } from "react-router-dom";
import { Github, Instagram, Linkedin, Twitter } from "lucide-react";

function Footer() {
  return (
    <footer className="absolute bottom-0 py-6 z-50 w-full bg-[--bg-color] flex items-center">
      <div className="flex justify-between items-center px-6 w-full flex-wrap-reverse gap-6 max-sm:justify-center">
        <div className="text-xs text-[#999] flex justify-between">
          © 2023 Somesh. All rights reserved.
        </div>
        <div className="flex gap-4">
          <Link
            target="_blank"
            to="https://www.linkedin.com/in/someshdahatonde/"
            className="hover:text-[#0077b5] hover:bg-[#e6f4f9] text-white p-2 border rounded-full transition-colors duration-300"
          >
            <Linkedin size={18} />
          </Link>
          <Link
            target="_blank"
            to="https://twitter.com/"
            className="hover:text-[#1DA1F2] hover:bg-[#e6f4f9] text-white p-2 border rounded-full transition-colors duration-300"
          >
            <Twitter size={18} />
          </Link>
          <Link
            target="_blank"
            to="https://github.com/Somesh-Dahatonde"
            className="hover:text-[#000] hover:bg-[#f5f5f5] text-white p-2 border rounded-full transition-colors duration-300"
          >
            <Github size={18} />
          </Link>
          <Link
            target="_blank"
            to="https://www.instagram.com/somesh_s_d/"
            className="hover:text-[#E1306C] hover:bg-[#fbeaf3] text-white p-2 border rounded-full transition-colors duration-300"
          >
            <Instagram size={18} />
          </Link>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
